import React, { useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import SignaturePad from "react-signature-canvas";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import {
    Box,
    Button,
    Text,
    Spinner,
    ListItem,
    Heading,
    HStack,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Icon,
    FormLabel,
    FormControl,
    Input,
    Textarea,
    Checkbox,
    CheckboxGroup,
    VStack,
    Flex,
    OrderedList,
    Link,
} from '@chakra-ui/react'
import styled from '@emotion/styled';

import { BsXCircle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";
import { BsFileEarmarkPdf } from 'react-icons/bs';

const schema = yup.object().shape({
    premisesAddress: yup.string().required('Please enter your premises address'),
    pay: yup.string().required('Please enter your payment method'),
    purpose: yup.string().required('Please enter your purpose'),
    limits: yup.array().min(1, "At least one option is required."),
    applicantName: yup.string().required('Please enter your name'),
    phone: yup
    .string()
    .required()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Invalid phone number"
    ),
    email: yup
    .string()
    .required()
    .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
    ),
    mailingAddress: yup.string().required('Please enter your mailing address'),
    physicalAddress: yup.string().required('Please enter your physical address'),
    sitePlan: yup.mixed()
    .test('required', 'Site Plan is Required', (value) => {
        console.log("file: ", value);
        return value && value.length;
    })
    .test('fileSize', 'Max file size 2 MB', (value, context) => {
        return value && value[0] && value[0].size <= 2000000;
    }),
    floorPlan: yup.mixed()
    .test('fileSize', 'Max file size  MB', (value, context) => {
    if (!value || !value.length) {
        return true;
    } else {
    return value && value[0] && value[0].size <= 2000000;
    }
  }),
    applicantSignature: yup.string().required("Signature is required.")
    .matches(/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
    "Signature must be png"
    ),
})

// const watchData = methods.watch();
// console.log(watchData)
const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const NewWaterAndSewerApplication = (props) => {

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [amount, setAmount] = useState(0);
    const [succeeded, setSucceeded] = useState(false);
   

    const {
      register,
      handleSubmit,
      watch,
      control,
      reset,
      formState: { errors, isValid },
    } = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        limits: [],
      },
      resolver: yupResolver(schema),
    });
    
    const watchData = watch();
    
    {
      /* Signature Pad */
    }
    let sigCanvas = useRef({});
  
    const formatIntoPng = () => {
      if (sigCanvas.current) {
        const dataURL = sigCanvas.current.toDataURL();
        return dataURL;
      }
    };
  
    const clear = () => sigCanvas.current.clear();
  
    function RequiredSpan() {
      return <span style={{ color: "#bf1650" }}>*</span>;
    }
    
    const errorStyles = {
      color: "#bf1650",
      fontSize: "1rem",
    };
    
    const scrollTop = () => {
      window.scrollTo(0, 0);
    };

   // create a Ref to access our form element
   const formRef = useRef(null)

   const FormSuccess = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Application submitted successfully!
        </AlertTitle>
        <AlertDescription >
        Thank you for submitting your application. We will be in touch with you shortly.<br />
        Please check your email for a copy of your application for your records.
  </AlertDescription>
    </Alert>
  </Box>
   )


   const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);
    
    // convert signature to blob
    const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    // Convert license array to object - parsing function only returns the last value
    convertArrayToObject(watchData.limits, "cityLimits");

    formData.append('cityLimits', JSON.stringify(watchData.limits));
    
    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-new-water-sewer-application', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    reset()
                    scrollTop()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};

    return (
        <Box>
            <Box id="theForm">
            {succeeded ? (
                <FormSuccess />
            ) : 
                (
                    <>
       
        {/* Send to Stripe, then Mailgun */}
        <form onSubmit={handleSubmit(sendMailgunFormData)} ref={formRef}>
              <Box mb="8">
                I hereby apply to the City of Driggs for permission to connect
                my premises at
                <Box w={{ base: "0", md: "20%" }} display="inline-block" mx="2">
                  <Input
                    placeholder="Address"
                    display="inline-block"
                    type="text"
                    name="premisesAddress"
                    {...register("premisesAddress")}
                  />
                </Box>
                with the City of Driggs water and/or sewer system and hereby
                agree as follows:
              </Box>

              <Box mb="8">
                <OrderedList>
                  <ListItem mb="4">
                    My agent shall, at my expense, make the requested connection
                    from the water main line to and including the water meter
                    and up to the place at which the water is to be used and/or
                    make the requested connection from the sewer main line the
                    place at which the sewer is to be discharged. I agree to pay
                    the city all connection charges and other fees as may be
                    fixed by the city council by resolution or ordinance.
                  </ListItem>

                  <ListItem mb="4">
                    <Box>
                      Additionally, I agree to pay $
                      <Box
                        w={{ base: "0", md: "20%" }}
                        display="inline-block"
                        mx="2"
                      >
                        <Input
                          display="inline-block"
                          type="number"
                          name="pay"
                          {...register("pay")}
                        />
                      </Box>
                      for inspection and overhead charges of the city as may be
                      fixed by the city council by resolution or ordinance from
                      time to time and any additional expenses as may be
                      incidental to my connection(s).
                    </Box>
                  </ListItem>
                  <ListItem mb="4">
                    The connection(s) so made, including the meter (water),
                    shall remain under the control of the city at all times, and
                    the city shall have access thereto at all times.
                  </ListItem>
                  <ListItem mb="4">
                    The location of the meter (water), whether on my premises or
                    at some point near my premises, may be decided solely by the
                    city.
                  </ListItem>
                  <ListItem mb="4">
                    I understand that if I fail to make active this
                    connection(s) within one year of the date of this
                    application that the connection(s) shall be considered to be
                    abandoned and the city has the authority to discontinue the
                    water and/or sewer connection without refund of connection
                    fees paid.
                  </ListItem>
                  <ListItem mb="4">
                    Before making connection with the water and/or sewer system,
                    I shall cause the plumbing upon my premises to be inspected
                    by the city and if the plumbing is not approved, I will
                    cause the plumbing to be rectified at my own expense to meet
                    the requirements of the city or of any other governmental
                    agency having jurisdiction to regulate the water and/or
                    sewer system within the city.
                  </ListItem>
                  <ListItem mb="4">
                    I will be bound by the rules, regulations, resolutions or
                    ordinances enacted now or hereafter by the city applicable
                    to the city's water and/or sewer system.
                  </ListItem>
                  <ListItem mb="4">
                    The purpose for which the water and/or sewer connection will
                    be used is
                    <Textarea
                      {...register("purpose")}
                    />
                    <p style={errorStyles}>
                    {errors.purpose && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.purpose?.message}</Box>
                      </HStack>
                    )}
                  </p>
                  </ListItem>
                  <ListItem mb="4">
                    The city shall have free access to the lines and meters
                    (water) installed under this agreement and, at reasonable
                    times, through my property if necessary.
                  </ListItem>

                  <ListItem mb="4">
                    For property outside of city limits: The applicant consents
                    to accept annexation of the property being served into the
                    city without opposition, if the city wishes to annex said
                    property at any time.
                    <Text mt="4">Please select requested service(s):</Text>
                    <FormControl mt="2">
                      <CheckboxGroup>
                        <VStack spacing="4px" align="left">
                          <Checkbox
                            value="Water in city limits"
                            name="limits"
                            {...register("limits")}
                          >
                            Water in city limits
                          </Checkbox>
                          <Checkbox
                            value="Sewer in city limits"
                            name="limits"
                            {...register("limits")}
                          >
                            Sewer in city limits
                          </Checkbox>
                          <Checkbox
                            value="Water out of city limits"
                            name="limits"
                            {...register("limits")}
                          >
                            Water out of city limits
                          </Checkbox>
                          <Checkbox
                            value="Sewer out of city limits"
                            name="limits"
                            {...register("limits")}
                          >
                            Sewer out of city limits
                          </Checkbox>
                        </VStack>
                      </CheckboxGroup>
                      <p style={errorStyles}>
                    {errors.limits && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.limits?.message}</Box>
                      </HStack>
                    )}
                  </p>
                    </FormControl>
                  </ListItem>
                </OrderedList>
              </Box>

                <Box mb="4">
                  <FormLabel>Applicant Name:<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("applicantName")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.applicantName && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.applicantName?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>

              <Flex flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Phone:<RequiredSpan /></FormLabel>
                  <Input type="tel" {...register("phone")} autoComplete="on" />
                  <p style={errorStyles}>
                    {errors.phone && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.phone?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: "4" }}>
                  <FormLabel>E-Mail:<RequiredSpan /></FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.email && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box my="8">
                <FormLabel>Mailing Address:<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("mailingAddress")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.mailingAddress && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.mailingAddress?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box my="8">
                <FormLabel>Physical Address:<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("physicalAddress")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.physicalAddress && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.physicalAddress?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormControl isInvalid={!!errors?.applicantSignature?.message}>
                  <FormLabel>Applicant Signature:</FormLabel>
                  <Box width="50%">
                    <Controller
                      name="applicantSignature"
                      control={control}
                      render={({ field }) => (
                        <SignaturePad
                          ref={sigCanvas}
                          onEnd={() =>
                            field.onChange(
                              formatIntoPng(sigCanvas.current.toDataURL())
                            )
                          }
                          penColor="black"
                          canvasProps={{
                            style: {
                              border: "1px solid #e2e8f0",
                              borderRadius: "0.375rem",
                              width: "100%",
                              height: "250px",
                              backgroundColor: "white",
                            },
                          }}
                        />
                      )}
                    />

                    {errors.applicantSignature && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.applicantSignature?.message}</Box>
                      </HStack>
                    )}
                    <Box my="2" textAlign="right">
                  <Button size="sm" onClick={clear} colorScheme="red">
                    <Icon as={BsXCircle} mr="2" />
                    Clear Signature
                  </Button>
                </Box>
                  </Box>
                </FormControl>
              </Box>
              <Box>
                    <input
                      type="hidden"
                      value={new Date()}
                      name="timestamp"
                      {...register("timestamp", { required: true })}
                    />
                  </Box>
              <Box mb="8">
                <Text> The following documents are required with this form:</Text>
                    <Box mb="8">
                      <FormControl>
                        <FormLabel>
                        <strong>Detailed Site Plan:</strong><RequiredSpan /> Must show existing &amp; proposed
                      water/sewer lines/sizes to the main line, any proposed
                      road/sidewalk cuts and location of proposed water meter.
                      Please <Link href="https://prismic-io.s3.amazonaws.com/city-of-driggs/34b7bda7-b3e0-4e2d-a284-d141e2520c60_samplesiteplan.pdf" target="_blank" rel="noopener noreferrer">see site plan sample here<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span></Link>. Incomplete applications will
                      not be accepted.<br /><small>Max file size 2 MB</small>
                        </FormLabel>
                        <input type="file" id="sitePlan" name="sitePlan" {...register("sitePlan")}  /> 
                        <p style={errorStyles}>{errors?.sitePlan && (<HStack spacing="5px"><VscWarning /> <Box>{errors?.sitePlan?.message}</Box></HStack>) }</p>
                      </FormControl>
                    </Box>

                    <FormControl>
                      <FormLabel><strong>If sewer, a floor plan showing WSFUs.</strong><br /><small>Max file size 2 MB</small></FormLabel>
                      <input type="file" id="floorPlan" name="floorPlan" {...register("floorPlan")}  /> 
                        <p style={errorStyles}>{errors?.floorPlan && (<HStack spacing="5px"><VscWarning /> <Box>{errors?.floorPlan?.message}</Box></HStack>) }</p>
                    </FormControl>
         
            
  
              </Box>
              <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp", { required: true })}
                      />
                    </Box>

              <Box my="6">
              <Button type="submit" textAlign="left" disabled={processing}>
                {processing ? (
                  <Spinner />
                ) : succeeded ? (
                  "Submitted!"
                ) : (
                    `Submit`
                )}
              </Button>
              {error && (
                  <div style={errorStyles} role="alert">
                    <HStack spacing="5px">
                      <VscWarning/><p className="card-error">{error}</p> 
                    </HStack>
                  </div>
                )}
            </Box>
            </form>
</>
                )
            }
            
        </Box>
        </Box>
    )
}

export default NewWaterAndSewerApplication